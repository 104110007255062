import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "../../api/AxiosHttp";

import BaseTitle from "../../components/base/BaseTitle";
import Accordion from "../../components/Accordion";

import NewsDirect from "../../images/distro-logos/news-direct.png";
import BlueMatrix from "../../images/distro-logos/bluematrix.png";
import Investing from "../../images/distro-logos/investing.png";
import NewsNow from "../../images/distro-logos/news-now.png";
import AppleNews from "../../images/distro-logos/apple-news.png";
import Invstr from "../../images/distro-logos/invstr.png";
import StockNews from "../../images/distro-logos/stock-news-api.png";
import AlphaNewsStream from "../../images/distro-logos/alpha-news-stream.png";
import IZooto from "../../images/distro-logos/izooto.png";
import GoogleNews from "../../images/distro-logos/google-news.png";
import BingNews from "../../images/distro-logos/bing-news.png";
import NlaMediaAccess from "../../images/distro-logos/nla-media.png";
import Proactive from "../../images/distro-logos/proactive.png";
import Youtube from "../../images/distro-logos/youtube.png";
import Linkedin from "../../images/distro-logos/linkedin.png";
import Facebook from "../../images/distro-logos/facebook.png";
import Twitter from "../../images/distro-logos/twitter.png";
import Reddit from "../../images/distro-logos/reddit.png";

const Distribution = () => {
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);

  const [accordionOpen, setAccordionOpen] = useState("NewsDirect");

  const handleAccordionToggle = (title) => {
    setAccordionOpen(accordionOpen !== title ? title : null);
  };

  useEffect(() => {
    logActivity();
  }, []);

  const logActivity = async () => {
    if (
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_distribution_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_distribution",
        type: "company",
      });
      sessionStorage.setItem(
        "view_distribution_" + companyData.company_id,
        true
      );
    }
  };

  return (
    <>
      <BaseTitle heading="Syndication Distribution and Social" />

      <div className="mb-10">
        <h2 className="text-3xl font-bold mb-12">
          Proactive’s reach extends far beyond our own network and websites. Our
          platform ensures that your content gets real-time distribution and
          syndications to top financially-focused websites, apps, social
          channels, and strategic partner destinations.
        </h2>

        <p className="mb-4">
          <strong>The Proactive Distribution Network™</strong> extends far
          beyond our own network and websites. Our platform actively distributes
          and syndicates your content in real-time to essential investor-focused
          websites, apps, social channels, and strategic partner destinations.
        </p>
        <p className="mb-4">
          <strong>Proactive Analytics™</strong> offers you key insights on how
          your content performs on Proactive websites and YouTube channels, but
          that is only a fraction of the story.
        </p>
        <p className="mb-4">
          <strong>The Proactive Distribution Network™</strong> is diverse and
          wide-spread – while we cannot measure viewer data from our partners in
          the same way, the “Syndication” button will show you where your
          original Proactive content has been indexed across the web.
        </p>
        <p>
          Leveraging our platform maximizes your visibility, helping you engage
          with a wider audience. Here is a summary of our Key Distribution
          Partners:
        </p>
      </div>

      <div className="mb-12">
        <h2 className="text-primary flex items-center text-xl md:text-[1.9rem] mb-10">
          Key Syndication Partners
        </h2>
        <Accordion
          logo={NewsDirect}
          title="NewsDirect"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.newsdirect.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  NewsDirect
                </a>{" "}
                is a state-of-the-art news and content distribution service
                specifically designed to meet the demands of modern media
                outreach.
              </p>
              <p>
                With a total distribution of 1.36bn and a potential visibility
                of 68m* NewsDirect are plugged into around 300 news sources.
              </p>
              <p className="text-sm italic">
                *Proprietary metric, represents potential visibility of our
                content factoring recency and total monthly traffic (Data
                Source: SEMrush).
              </p>
            </div>
            <div className="w-2/4">
              <p>
                NewsDirect’s top rated destinations for our video content
                include:
              </p>
              <ul className="list-image-custom pl-6">
                <li>Associated Press</li>
                <li>Benzinga</li>
                <li>Bloomberg</li>
                <li>MarketWatch</li>
                <li>MarketScreener</li>
                <li>NASDAQ</li>
                <li>Yahoo Finance</li>
              </ul>
              <p>
                And a number of big trade and international publications,
                alongside Regional media.
              </p>
            </div>
          </div>
          <img
            className="mt-4"
            alt="NewsDirect Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/NewsDirectScreenShot.jpg"
          />
        </Accordion>
        <Accordion
          logo={BlueMatrix}
          title="BlueMatrix"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.bluematrix.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  BlueMatrix
                </a>{" "}
                is the leading solution for investment research used by
                Investment Banks, Independent Analysts, Wealth Managers, Brokers
                and Investment Firms.
              </p>
              <p>
                Used across the global marketplace to securely manage the
                complete lifecycle of critical investment content.
              </p>
            </div>
            <div className="w-2/4">
              <p>Proactive’s equity research is syndicated via BlueMatrix:</p>
              <ul className="list-image-custom pl-6">
                <li>MiFID II Best Practice</li>
                <li>Global distribution</li>
                <li>Strong focus on Financial Services</li>
                <li>432m+ emails sent annually</li>
                <li>35% of North American research distributed</li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="BlueMatrix Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/BlueMatrixHomeScreenshot.jpg"
          />
        </Accordion>
        <Accordion
          logo={Investing}
          title="Investing.com"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.investing.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Investing.com
                </a>{" "}
                is a financial markets platform providing real-time data,
                quotes, charts, financial tools, breaking news and analysis
                across 250 exchanges around the world in 44 language editions.
              </p>
              <p>
                Investing.com is one of the top three global financial websites
                according to both SimilarWeb and Alexa.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                We developed unique RSS feeds for Investing.com allowing them to
                consume and republish our content as required.
              </p>
              <ul className="list-image-custom pl-6">
                <li>46,300,000 monthly uniques</li>
                <li>400 million sessions</li>
                <li>3bn monthly pageviews</li>
                <li>5.15m daily mobile visitors</li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="Investing Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/InvestingScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={NewsNow}
          title="NewsNow"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.newsnow.co.uk"
                  target="_blank"
                  rel="noreferrer"
                >
                  NewsNow
                </a>{" "}
                has been supporting and promoting credible, independent and
                public interest journalism for 25 years.
              </p>
              <p>
                NewsNow makes it easy for millions of people to discover news
                from thousands of publishers, including Proactive.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                NewsNow consume our content via up-to-the-minute RSS feeds
                before making our headlines available to their readers.
              </p>
              <ul className="list-image-custom pl-6">
                <li>100,000+ articles per day</li>
                <li>14,000 vetted publications</li>
                <li>24/7/365 coverage with updates every minute</li>
                <li>11m users per month</li>
                <li>70m articles read monthly</li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="AppleNews Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/NewsNowScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={AppleNews}
          title="AppleNews"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.apple.com/uk/apple-news"
                  target="_blank"
                  rel="noreferrer"
                >
                  AppleNews
                </a>{" "}
                offers Apple customers a single subscription to hundreds of
                magazines, online publications, and newspapers, with a free
                version also available.
              </p>
              <p>
                AppleNews delivers an incredibly diverse collection of the
                world’s best publications. Vivid photography and animation, rich
                videos and immersive layouts bring articles to life in stunning
                new ways.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                Proactive has tightly integrated the AppleNews API into our
                publishing CMS to dynamically push our content into their
                platform, where it is then disseminated to AppleNews users based
                on their preferences.
              </p>
              <ul className="list-image-custom pl-6">
                <li>19m paid subscribers (Est)</li>
                <li>Over 100m monthly users</li>
                <li>
                  126k+ articles published by Proactive to AppleNews platform in
                  5 years
                </li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="AppleNews Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/AppleNewsScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={Invstr}
          title="Invstr"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.invstr.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Invstr
                </a>{" "}
                is an academic financial app with a mission to empower everyone
                to learn to invest and take charge of their financial future.
              </p>
              <p>
                Through its award-winning smartphone app, Invstr is determined
                to democratize finance. Founded by Kerim Derhalli in 2013, the
                app combines fun, learning, and competition to break down
                barriers and help users become better, more confident investors.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                Invstr approached us in October 2022, asking if they could
                include our content within their platform. Content is delivered
                via a bespoke RSS feed in real-time to Invstr's global userbase.
              </p>
              <ul className="list-image-custom pl-6">
                <li>500k+ downloads on Google Play</li>
                <li>
                  Our content sits alongside each instrument or security for
                  ease of user research
                </li>
                <li>Features our logo and backlink</li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="iZoto Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/InvstrScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={StockNews}
          title="Stock News API"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.stocknewsapi.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stock News API
                </a>{" "}
                indexes article and video content from reputable news sources
                such as Proactive, CNBC, Zacks, Bloomberg, The Motley Fool, Fox
                Business, The Street, and many more.
              </p>
              <p>
                Stock News API has a strict curation process that ensures all
                news items are relevant to the specified company ticker.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                StockNewsAPI and their sister application CryptoNewsAPI have
                included Proactive's content within their service since 2021.
                Content is delivered via bespoke RSS feeds and is used by their
                customers in a wide variety of websites and applications.
              </p>
              <ul className="list-image-custom pl-6">
                <li>50,000+ monthly articles</li>
                <li>2,500 monthly videos</li>
                <li>50+ news sources</li>
                <li>Sentiment tracking included</li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="StockAPI Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/StockNewsScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={AlphaNewsStream}
          title="Alpha NEWS STREAM"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.alphanewsstream.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Alpha NEWS STREAM
                </a>{" "}
                offers stock and business headlines from around the web covering
                NYSE, NASDAQ, TSX and LSE companies in all sectors via on-demand
                cloud APIs.
              </p>
              <p>
                Delivered as a fully scalable data-as-a-service platform and
                hosted on AWS, big names including TechCrunch, Barrons and
                Mashable rely on the service for their own publications.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                One of our more recent distribution platforms, Alpha NEWS STREAM
                use a curated RSS feed to consume our content which is then
                included within their customer product.
              </p>
              <ul className="list-image-custom pl-6">
                <li>20,000+ headlines a week</li>
                <li>
                  Our content includes headline & backlinks with accreditation
                </li>
                <li>
                  Sourced from over 900 financial, business and stock newsfeeds
                </li>
                <li>News sources are human-curated and regularly updated</li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="Alpha News Stream"
            src="https://cdn.proactiveinvestors.com/thor/distro/AlphaNewsStreamScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={IZooto}
          title="iZooto"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.izooto.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  iZooto
                </a>{" "}
                is the world’s number 1 audience marketing platform designed for
                publishers. Working seamlessly across desktop, mobile and tablet
                devices, iZooto helps us to remain front-of-mind.
              </p>
              <p>
                Using iZooto’s web push notifications platform, iZooto helps
                Proactive increase return visitor rates, visitor retention and
                drive website traffic numbers on a consistent basis.
              </p>
            </div>
            <div className="w-2/4">
              <p>Proactive began using iZooto in June 2022.</p>
              <p>
                Since then we are pleased to have generated over 200k
                subscribers across each of our regional websites, who each
                receive carefully curated push notifications to their device(s)
                within minutes of publication.
              </p>
              <p>
                Engaging with a notification drives the user to our website
                where they can read and share content.
              </p>
            </div>
          </div>
          <img
            className="mt-4"
            alt="iZoto Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/IZotoScreenshot.jpg"
          />
        </Accordion>
      </div>

      <div className="mb-12">
        <h2 className="text-primary flex items-center text-xl md:text-[1.9rem] mb-10">
          Search Engines and their News Portals
        </h2>
        <Accordion
          logo={GoogleNews}
          title="Google News"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.news.google.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google News
                </a>{" "}
                is a personalised news aggregator that organises and highlights
                what’s happening in the world so that you can discover more
                about the stories that matter.
              </p>
              <p>
                With over 1B+ downloads of the Google News app for Android,
                along with an estimated web readership of 500m users per month,
                Google News is a trusted news destination on all subjects.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                Using a combination of XML sitemaps, Googlebot crawling and
                search signals, Proactive’s website(s) are crawled by Google at
                an average rate of 300 pages per minute 24/7/365.
              </p>
              <ul className="list-image-custom pl-6">
                <li>High SEO ranking</li>
                <li>Proactive featuring #1 for most key searches</li>
                <li>Thousands of daily followers</li>
                <li>
                  Google Discover includes personalised content on Android
                  devices outside of the official Google News app
                </li>
              </ul>
            </div>
          </div>
          <img
            className="mt-4"
            alt="Google News"
            src="https://cdn.proactiveinvestors.com/thor/distro/GoogleNewsScreenshot.png"
          />
        </Accordion>
        <Accordion
          logo={BingNews}
          title="Bing News"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                {" "}
                <a
                  className="text-primary underline"
                  href="https://www.bing.com/news"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bing News
                </a>{" "}
                is a popular news aggregation service provided by Microsoft.
              </p>
              <p>
                Bing News employs advanced algorithms and machine learning
                techniques to deliver relevant and timely news content to its
                users. With its user-friendly interface and customisable
                features, Bing News allows users to explore the latest news,
                discover trending topics, and stay informed about current
                events.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                Proactive’s website includes IndexNow, a recent initiative to
                inform search engines when new content has been published,
                allowing us to headline instantly.
              </p>
              <p>
                We are also an authorised publisher within Bing PubHub, a
                solution which integrates publisher headlines directly into
                Microsoft Outlook and Windows 10/11.{" "}
              </p>
            </div>
          </div>
          <img
            className="mt-4"
            alt="Bing News"
            src="https://cdn.proactiveinvestors.com/thor/distro/BingNewsScreenshot.png"
          />
        </Accordion>
      </div>
      <div className="mb-12">
        <h2 className="text-primary flex items-center text-xl md:text-[1.9rem] mb-10">
          Licensed Media Aggregators and Re-Distributors (NLA)
        </h2>
        <Accordion
          logo={NlaMediaAccess}
          title="NLA Media Access"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                <a
                  className="text-primary underline"
                  href="https://www.nlamediaaccess.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  NLA Media Access (NLA)
                </a>{" "}
                is a publisher-owned rights licensing and publisher services
                business with a core aim of supporting journalism. The NLA works
                on behalf of the UK’s newspaper and magazine publishers,
                including Proactive.
              </p>
              <p>
                The NLA currently licenses over 200,000 businesses and
                organisations ranging from large government bodies, PLCs, and
                limited companies to partnerships and public relations agencies.
              </p>
            </div>
            <div className="w-2/4">
              <p>
                Proactive has worked with NLA for many years, and deliver
                licensed content in a variety of ways, including bespoke RSS
                feeds, direct push over SFTP and authorised direct scraping of
                our web pages by their clients including:
              </p>
              <ul className="list-image-custom pl-6">
                <li>Factiva</li>
                <li>Thomson Reuters</li>
                <li>Refinitiv/Eikon</li>
                <li>NewsBank</li>
                <li>Lexis Nexis</li>
                <li>Moody’s </li>
                <li>Proquest</li>
                <li>Cengage</li>
                <li>Opoint</li>
                <li>InfoMedia</li>
                <li>Ebiquity</li>
                <li>Muckrack</li>
                <li>Notified</li>
                <li>Meltwater + more</li>
              </ul>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="mb-12">
        <h2 className="text-primary flex items-center text-xl md:text-[1.9rem] mb-10">
          Newsletters and Website Subscribers
        </h2>
        <Accordion
          logo={Proactive}
          title="Proactive Newsletters"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <div className="flex gap-8">
            <div className="w-2/4">
              <p className="text-2xl font-bold mb-4">
                Proactive Newsletters and Registered Subscribers receiving
                Company Alerts
              </p>
              <p>
                Proactive’s websites include a subscription function within
                MyProactive, allowing our visitors to follow companies, receive
                alerts when new content is published and create customised news
                feeds allowing them to focus on the topics and companies of
                interest.
              </p>
              <ul className="list-image-custom pl-6">
                <li>
                  27,000+ subscribers for regional Daily and Weekly newsletters
                </li>
                <li>24,000+ subscribers for UK and AU events mailings</li>
                <li>143,000+ subscribers on our websites for company alerts</li>
              </ul>
            </div>
            <div className="w-2/4">
              <img
                className="mt-4"
                alt="Proactive Newsletter Distribution"
                src="https://cdn.proactiveinvestors.com/thor/distro/PANewsletterScreenshot.png"
              />
            </div>
          </div>
        </Accordion>
      </div>
      <div className="mb-12">
        <h2 className="text-primary flex items-center text-xl md:text-[1.9rem] mb-10">
          Social Media Platforms
        </h2>
        <Accordion
          logo={Youtube}
          title="Youtube"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <p className="text-2xl font-bold mb-4">
            Since joining{" "}
            <a
              className="text-primary underline"
              href="https://www.youtube.com/proactive247"
              target="_blank"
              rel="noreferrer"
            >
              YouTube
            </a>{" "}
            in 2009, Proactive has published tens of thousands of client videos.
            With a subscriber count of 51.5k and growing month-on-month, our
            channel and video content has enjoyed:
          </p>
          <ul className="list-image-custom pl-6">
            <li>35m+ views</li>
            <li>2m hours watch time</li>
            <li>Audience focus in UK, US, CA and Australia</li>
            <li>Viewers aged 18 - 65+</li>
            <li>70% male, 30% female</li>
            <li>Dedicated per-video analytics available</li>
          </ul>
          <img
            className="mt-4"
            alt="YouTube Distribution"
            src="https://cdn.proactiveinvestors.com/thor/distro/YoutubeScreenshot.jpg"
          />
        </Accordion>
        <Accordion
          logo={Linkedin}
          title="LinkedIn"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <p className="text-2xl font-bold mb-4">
            With more than 10,000 followers, Proactive automatically posts all
            articles to our{" "}
            <a
              className="text-primary underline"
              href="https://www.linkedin.com/company/proactive-investors"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>{" "}
            profile where they can be liked, shared and commented on.
          </p>
          <p>
            We also use the channel to promote our online and in-person investor
            focused events.
          </p>
        </Accordion>
        <Accordion
          logo={Twitter}
          title="Twitter"
          text="@proactive_UK @proactive_CA @proactive_NA @proactive_AU"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <p className="text-2xl font-bold mb-4">
            With more than 10,000 followers, Proactive automatically posts all
            articles to our LinkedIn profile where they can be liked, shared and
            commented on.
          </p>
          <p>
            We also use the channel to promote our online and in-person investor
            focused events.
          </p>
        </Accordion>
        <Accordion
          logo={Facebook}
          title="Facebook"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <p className="text-2xl font-bold mb-4">
            Following a recent overhaul, Proactive once again posts all articles
            to our{" "}
            <a
              className="text-primary underline"
              href="www.facebook.com/ProactiveInvestors"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>{" "}
            page where they can be liked, shared and commented on.
          </p>
          <p>
            We also use the channel to promote our online and in-person investor
            focused events.
          </p>
          <ul className="list-image-custom pl-6">
            <li>27k followers</li>
            <li>27k likes</li>
            <li>Rating of 4.9 (71 independent reviews)</li>
          </ul>
        </Accordion>
        <Accordion
          logo={Reddit}
          title="Reddit"
          toggle={handleAccordionToggle}
          accordionOpen={accordionOpen}
        >
          <p className="text-2xl font-bold mb-4">
            At less than 1 year old,{" "}
            <a
              className="text-primary underline"
              href="https://www.reddit.com/r/ProactiveStocks"
              target="_blank"
              rel="noreferrer"
            >
              Reddit
            </a>{" "}
            represents a new social channel for Proactive.
          </p>
          <p>
            Created as a grassroots community bu
            https://www.reddit.com/r/ProactiveStocks/ for investors interested
            in small and mid-cap stocks, Proactive automates a daily News
            Snapshot to our subreddit.
          </p>
        </Accordion>
      </div>
    </>
  );
};

export default Distribution;
